import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store/index'
import VueAzureMaps from 'vue-azure-maps'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.use(VueAzureMaps, {
  key: atob('NFBnTTllOHk5WVFJc0hPMlZvbUxYcDB6Tl96bzJzbldYQWQ1ZmNiM1RPOA=='),
})

Vue.use(VueGtag, {
  config: {
    id: atob('QVctODI1ODIzMTAy'),
  },
})
