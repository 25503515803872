

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { LeagueInfo } from '@/models/LeagueInfo'
import date from '@/filters/date'
import dayjs from 'dayjs'

@Component({
  filters: {
    date,
  },
})
export default class LeagueListItem extends Vue {
  @Prop({ type: Object, required: true })
  private league!: LeagueInfo

  private get isComingSoon() {
    if (!this.league.registrationBeginDate) {
      return false
    }

    return new Date(this.league.registrationBeginDate).valueOf() > Date.now()
  }

  private get hasInfo() {
    return this.league && this.league.leagueInformation && this.league.leagueInformation.trim().length
  }

  private get html() {
    return this.hasInfo ? this.league.leagueInformation! : ''
  }

  private get isOpen() {
    if (this.isComingSoon || (!this.league.registrationBeginDate && !this.league.registrationEndDate)) {
      return false
    }

    if (!this.league.registrationEndDate && this.league.registrationBeginDate && this.league.firstGameDate) {
      const now = Date.now()
      const firstGameDate = new Date(this.league.firstGameDate).valueOf()
      const firstGamePlus90 = firstGameDate + 90 * 24 * 60 * 60 * 1000
      return new Date(this.league.registrationBeginDate).valueOf() <= now && now <= firstGamePlus90
    }

    if (this.league.registrationEndDate) {
      return new Date(this.league.registrationEndDate).valueOf() >= Date.now()
    }
  }

  private get isClosed() {
    if (!this.league.registrationEndDate || !this.league.awardsDate) {
      return false
    }

    const now = Date.now()
    return (
      new Date(this.league.registrationEndDate).valueOf() < now &&
      new Date(this.league.awardsDate).valueOf() >= now
    )
  }

  private get isBrochurePresent() {
    if (this.league.englishBrochureUrl || this.league.spanishBrochureUrl) {
      return true
    }

    return false
  }

  private get isCamp() {
    if (this.league.leagueType && this.league.leagueType === 'League') return false

    return true
  }

  private get practiceStartWeekDate() {
    if (!this.league.firstPracticeDate) {
      return ''
    }

    const date = new Date(this.league.firstPracticeDate)
    return this.getMondayDate(date)
  }

  private get firstGameWeekDate() {
    if (!this.league.firstGameDate) {
      return ''
    }

    const date = new Date(this.league.firstGameDate)
    return this.getMondayDate(date)
  }

  private get colors() {
    if (!this.league.colors) {
      return []
    }

    const colorsData = this.league.colors.split('|')
    const htmlColors = []

    for (const colorData of colorsData) {
      const dataPieces = colorData.split(',')

      if (dataPieces.length === 2) {
        if (dataPieces[1][0] === '#') {
          htmlColors.push(dataPieces[1])
        } else {
          htmlColors.push(`#${dataPieces[1]}`)
        }
      }
    }

    return htmlColors
  }

  private get isFacebookPresent() {
    if (!this.league.facebookUrl) return false

    return true
  }

  private get isTwitterPresent() {
    if (!this.league.twitterUrl) return false

    return true
  }

  private get isLeagueMsgPresent() {
    if (!this.league.leagueInformation) return false

    return true
  }

  private getOPRDate(dt: Date) {
    if (dayjs(dt).year() === 1901) return ''

    return dayjs(dt).format('MM/DD/YYYY hh:mm a')
  }

  /** Get the previous Monday date, or the date itself if it is a Monday. This is useful for
   * displaying "Week of X" to a user if a more specific date is not desired.
   */
  private getMondayDate(d: Date) {
    const date = new Date(d)
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    const monday = new Date(date.setDate(diff))
    return monday
  }
}
