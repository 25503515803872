






























import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import UpwardButton from '@/elements/UpwardButton.vue'
import { Component, Mixins } from 'vue-property-decorator'
import SearchFormMixin from '@/components/SearchFormMixin'

@Component({
  components: {
    TextInput,
    SelectInput,
    UpwardButton,
  },
})
export default class Home extends Mixins(SearchFormMixin) {}
