








import { Component, Vue, Prop } from 'vue-property-decorator'
import LeagueListItem from '@/components/LeagueListItem.vue'
import { LeagueInfo } from '@/models/LeagueInfo'

@Component({
  components: {
    LeagueListItem,
  },
})
export default class LeagueList extends Vue {
  @Prop({ type: Array, required: true })
  private leagues!: LeagueInfo[]
}
