

















import * as searchStore from '@/store/search'
import { Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import PartnerListItem from '@/components/PartnerListItem.vue'
import { PartnerProgramInfo } from '@/models/PartnerProgramInfo'

@Component({
  components: {
    PartnerListItem,
  },
})
export default class PartnerList extends Vue {
  @Getter(searchStore.getterNames.programs, { namespace: searchStore.namespace })
  private partners!: PartnerProgramInfo[]

  private get partnerID() {
    return this.$route.params.partnerID
  }

  private showPreview(partner: PartnerProgramInfo | null) {
    if (!partner) {
      this.$router.push('/search')
      return
    }

    this.$router.push(`/search/${partner.partnerID}`)
  }
}
