



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { PartnerProgramInfo } from '@/models/PartnerProgramInfo'

@Component
export default class PartnerListItem extends Vue {
  @Prop({ type: Object, required: true })
  private partner!: PartnerProgramInfo

  @Prop({ type: Boolean, default: false })
  private isActive!: boolean
}
