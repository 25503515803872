





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ type: Boolean, required: true }) private readonly value!: boolean
  @Prop({ type: String, required: true }) private readonly title!: string
  @Prop({ type: Boolean, required: false, default: false }) private readonly isSmall!: boolean

  private hide() {
    this.$emit('input', false)
    this.$emit('change', false)
  }

  private get isVisible() {
    return this.value
  }
}
