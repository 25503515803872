






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class InputLabel extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: String, default: '', required: false })
  private readonly inputName!: string
}
