











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
  @Prop({ type: Boolean, required: true }) private readonly value!: boolean
  @Prop({ type: Boolean, required: false, default: true }) private readonly dismissable!: boolean
  @Prop({ type: Number, required: false, default: 10 * 1000 }) private readonly timeout!: number

  private timeoutHandle: number | null = null

  private dismiss() {
    this.$emit('input', false)
    this.$emit('change', false)
  }

  @Watch('value')
  private onValueChanged() {
    if (!this.value && this.timeoutHandle) {
      clearTimeout(this.timeoutHandle)
    }

    if (this.value && this.timeout) {
      this.timeoutHandle = setTimeout(this.dismiss, this.timeout)
    }
  }
}
