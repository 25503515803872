import { SelectOption } from '@/models/SelectOption'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import * as searchStore from '@/store/search'
import { SearchArgs } from '@/models/SearchArgs'

@Component
export default class SearchFormMixin extends Vue {
  private postalCode = ''
  private sport = ''
  private programType = ''
  private radius = ''
  private loading = false

  @Getter(searchStore.getterNames.programTypeOptions, { namespace: searchStore.namespace })
  private programTypeOptions!: SelectOption[]

  @Getter(searchStore.getterNames.sportOptions, { namespace: searchStore.namespace })
  private sportOptions!: SelectOption[]

  private radiusOptions: SelectOption[] = [
    { text: '25 Miles', value: '25' } as SelectOption,
    { text: '50 Miles', value: '50' } as SelectOption,
  ]

  //Issue 4736 - selecting a softball camp is generating errors on the backend.
  //Adding logic to remove selections since as of 4/24, softball camps are not offered.
  get filteredProgramTypeOptions(): SelectOption[] {
    if (this.sport === 'softball') {
      return this.programTypeOptions.filter(option => option.value !== 'camp' && option.value !== 'all')
    } else {
      return this.programTypeOptions
    }
  }

  get filteredSportOptions(): SelectOption[] {
    if (this.programType === 'camp') {
      return this.sportOptions.filter(option => option.value !== 'softball')
    } else {
      return this.sportOptions
    }
  }

  @Getter(searchStore.getterNames.searchArgs, { namespace: searchStore.namespace })
  private storedArgs!: SearchArgs

  @Action(searchStore.actionNames.doSearch, { namespace: searchStore.namespace })
  private doSearch!: ({ searchArgs }: { searchArgs: SearchArgs }) => Promise<boolean>

  private created() {
    this.postalCode = this.storedArgs.postalCode || ''
    this.sport = this.storedArgs.sport || this.sportOptions[0].value
    this.programType = this.storedArgs.programType || this.programTypeOptions[0].value
    this.radius = `${this.storedArgs.radius}` || '25'
  }

  private async onSubmit() {
    if (this.loading) {
      return
    }

    this.loading = true

    try {
      const searchArgs = {
        postalCode: this.postalCode,
        programType: this.programType,
        sport: this.sport,
        radius: Number(this.radius),
      } as SearchArgs

      this.$gtag.event('search', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        search_term: JSON.stringify(searchArgs),
      })

      const success = await this.doSearch({
        searchArgs,
      })

      const destination = '/search'

      if (success && this.$route.path !== destination) {
        this.$router.push(destination)
      }
    } finally {
      this.loading = false
    }
  }
}
