import { Module, GetterTree, MutationTree, ActionTree, Commit } from 'vuex'
import { RootState } from './rootState'
import { SearchArgs } from '@/models/SearchArgs'
import { SelectOption } from '../models/SelectOption'
import searchClient from '@/clients/searchClient'
import * as errorStore from '@/store/errors'
import { ErrorObject } from '../models/ErrorObject'
import { PartnerProgramInfo } from '../models/PartnerProgramInfo'

const commitToErrorStore = (commit: Commit, mutationName: string, payload: any) => {
  commit(`${errorStore.namespace}/${mutationName}`, payload, { root: true })
}

const showError = (commit: Commit, message: string) => {
  commitToErrorStore(commit, errorStore.mutationNames.add, {
    item: { message } as ErrorObject,
  })
}

const getValidationError = (args: SearchArgs) => {
  const { postalCode, programType, sport } = args

  if (!postalCode) {
    return 'The postal code is required'
  }

  if (!/^[0-9]{5}$/.test(postalCode)) {
    return 'The postal code is not valid'
  }

  if (!sport) {
    return 'The sport is required'
  }

  if (!programType) {
    return 'The program type is required'
  }

  return ''
}

const sportOptions: SelectOption[] = [
  {
    value: '',
    text: '',
  },
  {
    value: 'basketball',
    text: 'Basketball',
  },
  {
    value: 'soccer',
    text: 'Soccer',
  },
  {
    value: 'cheerleading',
    text: 'Cheerleading',
  },
  {
    value: 'football',
    text: 'Flag Football',
  },
  {
    value: 'volleyball',
    text: 'Volleyball',
  },
  {
    value: 'baseball',
    text: 'Baseball',
  },
  {
    value: 'softball',
    text: 'Softball',
  },
]

const programTypeOptions: SelectOption[] = [
  {
    value: 'all',
    text: 'All',
  },
  {
    value: 'league',
    text: 'Leagues',
  },
  {
    value: 'camp',
    text: 'Camps',
  },
]

interface SearchState {
  searchArgs: SearchArgs
  sportOptions: SelectOption[]
  programTypeOptions: SelectOption[]
  searchResults: PartnerProgramInfo[]
}

const searchState: SearchState = {
  searchArgs: {
    postalCode: '',
    programType: programTypeOptions[0].value,
    sport: sportOptions[0].value,
    radius: 25,
  },
  sportOptions,
  programTypeOptions,
  searchResults: [],
}

export enum getterNames {
  sportOptions = 'sportOptions',
  programTypeOptions = 'programTypeOptions',
  searchArgs = 'searchArgs',
  areSearchArgsValid = 'areSearchArgsValid',
  programs = 'programs',
}

const searchGetters: GetterTree<SearchState, RootState> = {
  [getterNames.sportOptions]: state => state.sportOptions,
  [getterNames.programTypeOptions]: state => state.programTypeOptions,
  [getterNames.searchArgs]: state => state.searchArgs,
  [getterNames.programs]: state => state.searchResults,
  [getterNames.areSearchArgsValid]: state => !getValidationError(state.searchArgs),
}

export enum mutationNames {
  setSearchArgs = 'setSearchArgs',
  setSearchResults = 'setSearchResults',
}

const mutations: MutationTree<SearchState> = {
  [mutationNames.setSearchArgs](state, { searchArgs }: { searchArgs: SearchArgs }) {
    state.searchArgs = {
      postalCode: searchArgs.postalCode,
      programType: searchArgs.programType,
      sport: searchArgs.sport,
      radius: searchArgs.radius,
    }
  },
  [mutationNames.setSearchResults](state, { searchResults }: { searchResults: PartnerProgramInfo[] }) {
    state.searchResults = [...searchResults]
  },
}

export enum actionNames {
  doSearch = 'doSearch',
}

const actions: ActionTree<SearchState, RootState> = {
  async [actionNames.doSearch]({ commit }, { searchArgs }: { searchArgs: SearchArgs }): Promise<boolean> {
    const { postalCode, programType, sport, radius } = searchArgs
    commitToErrorStore(commit, errorStore.mutationNames.clearAll, null)
    const validationError = getValidationError(searchArgs)

    if (validationError) {
      showError(commit, validationError)
      return false
    }

    const result = await searchClient.retrieve(sport, programType, postalCode, radius)

    if (result.isSuccess) {
      commit(mutationNames.setSearchArgs, { searchArgs })

      if (result.data && result.data.length) {
        commit(mutationNames.setSearchResults, { searchResults: result.data })
      } else {
        commit(mutationNames.setSearchResults, { searchResults: [] })
      }

      return true
    }

    return false
  },
}

export const namespace = 'search'

export const search: Module<SearchState, RootState> = {
  namespaced: true,
  state: searchState,
  mutations,
  getters: searchGetters,
  actions,
}
