import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from './rootState'
import partnerLookupClient from '@/clients/partnerLookupClient'
import { LeagueInfo } from '../models/LeagueInfo'

interface PartnerProgramState {
  partnerResults: LeagueInfo[]
}

const partnerProgramState: PartnerProgramState = {
  partnerResults: [],
}

export enum getterNames {
  leagues = 'leagues',
  partner = 'partner',
}

const searchGetters: GetterTree<PartnerProgramState, RootState> = {
  [getterNames.leagues]: state => state.partnerResults,
  [getterNames.partner]: state => {
    return state.partnerResults[state.partnerResults.length - 1]
  },
}

export enum mutationNames {
  setPartnerResults = 'setPartnerResults',
}

const mutations: MutationTree<PartnerProgramState> = {
  [mutationNames.setPartnerResults](state, { partnerResults }: { partnerResults: LeagueInfo[] }) {
    state.partnerResults = [...partnerResults]
  },
}

export enum actionNames {
  doPartnerLookup = 'doPartnerLookup',
}

const actions: ActionTree<PartnerProgramState, RootState> = {
  async [actionNames.doPartnerLookup]({ commit }, { partnerID }: { partnerID: string }): Promise<boolean> {
    const result = await partnerLookupClient.retrieve(partnerID)

    if (result.isSuccess) {
      if (result.data && result.data.length) {
        commit(mutationNames.setPartnerResults, { partnerResults: result.data })
      } else {
        commit(mutationNames.setPartnerResults, { partnerResults: [] })
      }
      return true
    } else {
      return false
    }
  },
}

export const namespace = 'partnerProgram'

export const partnerProgram: Module<PartnerProgramState, RootState> = {
  namespaced: true,
  state: partnerProgramState,
  mutations,
  getters: searchGetters,
  actions,
}
