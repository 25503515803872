















import Alert from '@/components/Alert.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Mutation, Getter } from 'vuex-class'
import { getterNames, mutationNames, namespace } from '@/store/errors'
import { ErrorObject } from '@/models/ErrorObject'

@Component({
  components: {
    Alert,
  },
})
export default class TheErrorRenderer extends Vue {
  @Getter(getterNames.currentItem, { namespace })
  private currentError!: ErrorObject | null

  @Getter(getterNames.allItems, { namespace })
  private allErrors!: ErrorObject[]

  @Mutation(mutationNames.clearCurrent, { namespace })
  private clearCurrentError!: () => void

  @Mutation(mutationNames.clearAll, { namespace })
  private clearAll!: () => void

  private get itemCount() {
    return this.allErrors.length
  }

  @Watch('$route')
  private onRouteChange() {
    this.clearAll()
  }

  private get alertIsVisible() {
    return !!this.currentError
  }
}
