






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Loading extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private readonly isSmall!: boolean
}
