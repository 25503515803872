import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { UpwardInvalidResult } from '@/models/UpwardInvalidResult'

const baseUrl = 'https://postallocationapiservices.azurewebsites.net/api/'

export interface RestServiceResult<T> {
  errorObject: UpwardInvalidResult | null
  isSuccess: boolean
  status: number
  data: T | null
}

export function convertToRestServiceResult<T>(instance: any) {
  const implementsInterface =
    typeof instance === 'object' &&
    instance !== null &&
    'errorObject' in instance &&
    'isSuccess' in instance &&
    'data' in instance

  return implementsInterface ? (instance as RestServiceResult<T>) : null
}

const isUpwardInvalidResult = (instance: any) => {
  return (
    typeof instance === 'object' &&
    instance !== null &&
    'id' in instance &&
    'message' in instance &&
    'errors' in instance
  )
}

const getRestServiceResult = <T>(axiosResponse: AxiosResponse<any>) => {
  const isSuccessCode = Math.floor(axiosResponse.status / 100) === 2
  const isErrorObject = isUpwardInvalidResult(axiosResponse.data)

  return {
    data: isErrorObject ? null : (axiosResponse.data as T),
    isSuccess: isSuccessCode && !isErrorObject,
    status: axiosResponse.status,
    errorObject: isErrorObject
      ? axiosResponse.data
      : ({
          message: axiosResponse.statusText || 'Unknown error',
        } as UpwardInvalidResult),
  } as RestServiceResult<T>
}

const wrapApiCall = async <T>(callback: () => Promise<AxiosResponse<any>>) => {
  return await callback()
    .then(result => {
      return getRestServiceResult<T>(result)
    })
    .catch(error => {
      if (error.message) {
        throw error.message
      }

      throw error
    })
}

const post = async <T>(
  resource: string,
  data: object | null,
  config: AxiosRequestConfig | undefined = undefined
) => {
  const fullUrl = `${baseUrl}${resource}`
  return wrapApiCall<T>(() => axios.post(fullUrl, data, config))
}

const put = async <T>(resource: string, data: object | null) => {
  const fullUrl = `${baseUrl}${resource}`
  return wrapApiCall<T>(() => axios.put(fullUrl, data))
}

const get = async <T>(resource: string, params = {}) => {
  const fullUrl = `${baseUrl}${resource}`
  return wrapApiCall<T>(() => axios.get(fullUrl, { params }))
}

const deleteMethod = async <T>(resource: string) => {
  const fullUrl = `${baseUrl}${resource}`
  return wrapApiCall<T>(() => axios.delete(fullUrl))
}

const patch = async <T>(resource: string, data: object | null) => {
  const fullUrl = `${baseUrl}${resource}`
  return wrapApiCall<T>(() => axios.patch(fullUrl, data))
}

const setToken = (token: string | null | undefined) => {
  axios.defaults.headers.common = {
    Authorization: token ? `Bearer ${token}` : null,
  }
}

export default {
  get,
  post,
  put,
  delete: deleteMethod,
  patch,
  setToken,
}
