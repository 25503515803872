




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MapPoint } from '@/models/MapPoint'
import atlas from 'azure-maps-control'

import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer,
  AzureMapZoomControl,
  AzureMapImageSpriteIcon,
  AzureMapUserPosition,
  AzureMapPopup,
} from 'vue-azure-maps'

@Component({
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer,
    AzureMapZoomControl,
    AzureMapImageSpriteIcon,
    AzureMapUserPosition,
    AzureMapPopup,
  },
})
export default class UpwardMap extends Vue {
  @Prop({ type: Array, required: true })
  private readonly points!: MapPoint[]

  private isCustomIconAdded = false

  private readonly userPosition = {
    symbolLayerOptions: {
      iconOptions: {
        image: 'pin-blue',
        allowOverlap: true,
        ignorePlacement: true,
      },
      textOptions: {
        allowOverlap: true,
        ignorePlacement: true,
      },
    } as atlas.SymbolLayerOptions,
  }

  private readonly customIconSymbolLayerOptions = {
    iconOptions: {
      image: 'upward-star',
      allowOverlap: true,
      ignorePlacement: true,
    },
    textOptions: {
      allowOverlap: true,
      ignorePlacement: true,
    },
  } as atlas.SymbolLayerOptions

  private get partnerId() {
    return this.$route.params.partnerID
  }

  private get selectedPoint() {
    if (!this.partnerId) {
      return null
    }

    return this.points.find(p => p.partnerID === this.partnerId)
  }

  private get mapCenter() {
    if (this.selectedPoint) {
      return [this.selectedPoint.longitude, this.selectedPoint.latitude]
    }

    if (this.points.length) {
      const firstPoint = this.points[0]
      return [firstPoint.longitude, firstPoint.latitude]
    }

    const userPosition: any = this.$refs.userPosition

    if (userPosition && userPosition.longitude && userPosition.latitude) {
      return [userPosition.longitude, userPosition.latitude]
    }

    // We don't know the user's position and there are no results. This is Upward HQ's coordinates.
    return [-82.059108, 34.943016]
  }

  private onClick(e: atlas.MapMouseEvent) {
    if (!e || !e.shapes || !e.shapes.length) {
      return
    }

    const selectedShape = e.shapes[0] as atlas.Shape
    const mapPoint = selectedShape ? (selectedShape.getProperties() as MapPoint) : null

    if (!mapPoint) {
      return
    }

    const valueToEmit = mapPoint ? mapPoint.partnerID : ''
    this.$emit('click', valueToEmit)
  }
}
