









import { Component, Vue, Prop } from 'vue-property-decorator'
import Loading from '@/elements/Loading.vue'

@Component({
  components: {
    Loading,
  },
})
export default class UpwardButton extends Vue {
  @Prop({ type: String, required: true })
  private readonly text!: string

  @Prop({ type: Boolean, default: false })
  private readonly isLoading!: boolean
}
