


































































import UpwardButton from '@/elements/UpwardButton.vue'
import * as searchStore from '@/store/search'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { Getter } from 'vuex-class'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import SearchFormMixin from '@/components/SearchFormMixin'
import UpwardMap from '@/components/UpwardMap.vue'
import PartnerList from '@/components/PartnerList.vue'
import { PartnerProgramInfo } from '@/models/PartnerProgramInfo'
import { MapPoint } from '@/models/MapPoint'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    TextInput,
    SelectInput,
    UpwardButton,
    UpwardMap,
    PartnerList,
    Modal,
  },
})
export default class Search extends Mixins(SearchFormMixin) {
  @Prop({ required: false, type: String })
  private partnerID!: string | null

  @Getter(searchStore.getterNames.areSearchArgsValid, { namespace: searchStore.namespace })
  private areSearchArgsValid!: boolean

  @Getter(searchStore.getterNames.programs, { namespace: searchStore.namespace })
  private partners!: PartnerProgramInfo[]

  private renderMap = false
  private modalIsVisible = false

  private get points() {
    return this.partners.map(p => {
      return {
        latitude: Number(p.latitude),
        longitude: Number(p.longitude),
        name: p.partnerName,
        address1: p.address1,
        address2: p.address2,
        partnerID: p.partnerID,
        city: p.city,
        state: p.state,
        postalCode: p.postalCode,
      } as MapPoint
    })
  }

  private mounted() {
    if (!this.areSearchArgsValid) {
      this.$router.push('/')
    } else {
      this.renderMap = true
    }
  }

  private selectPoint(partnerID: string) {
    this.$router.push(`/search/${partnerID}`)
  }

  @Watch('partners', { immediate: true })
  private onPartnersChange() {
    this.modalIsVisible = this.partners.length === 0
  }
}
