




















































































import { Getter, Action } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as partnerStore from '@/store/partnerProgram'
import { Partner } from '@/models/Partner'
import { LeagueInfo } from '@/models/LeagueInfo'
import LeagueList from '@/components/LeagueList.vue'

@Component({
  components: {
    LeagueList,
  },
})
export default class PartnerDetail extends Vue {
  @Prop({ required: true, type: String })
  private partnerID!: string

  @Getter(partnerStore.getterNames.leagues, { namespace: partnerStore.namespace })
  private leagues!: LeagueInfo[]

  @Getter(partnerStore.getterNames.partner, { namespace: partnerStore.namespace })
  private partner!: Partner

  @Action(partnerStore.actionNames.doPartnerLookup, { namespace: partnerStore.namespace })
  private doPartnerLookup!: ({ partnerID }: { partnerID: string }) => Promise<boolean>

  private arePreviousProgramsShown = false
  private showContactInfo = false

  private get allLeagues() {
    const leagues = this.leagues ? this.leagues : []
    leagues.sort((a, b) => {
      const aDate = a.registrationBeginDate ? new Date(a.registrationBeginDate) : new Date()
      const bDate = b.registrationBeginDate ? new Date(b.registrationBeginDate) : new Date()
      return aDate.getTime() - bDate.getTime()
    })

    if (leagues.length > 0) this.showContactInfo = true

    return leagues
  }

  private get activeLeagues() {
    return this.allLeagues.filter(l => {
      return Boolean(l.isHistorical) === false
    })
  }

  private get previousLeagues() {
    return this.allLeagues.filter(l => {
      return Boolean(l.isHistorical) === true
    })
  }

  private async mounted() {
    if (!this.$route.params.partnerID) {
      this.$router.push('/search')
    } else {
      this.partnerID = this.$route.params.partnerID
      await this.doPartnerLookup({ partnerID: this.partnerID })

      if (this.activeLeagues.length == 0 && this.previousLeagues.length) {
        this.arePreviousProgramsShown = true
      }
    }
  }

  private togglePreviousPrograms() {
    this.arePreviousProgramsShown = !this.arePreviousProgramsShown
  }
}
