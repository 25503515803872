



































import '@/css'

import { Component, Vue, Watch } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import * as errors from '@/store/errors'
import TheErrorRenderer from '@/components/TheErrorRenderer.vue'
import { ErrorObject } from './models/ErrorObject'

@Component({
  components: {
    TheErrorRenderer,
  },
})
export default class App extends Vue {
  @Mutation(errors.mutationNames.add, { namespace: errors.namespace })
  private addErrorToStore!: ({ item }: { item: ErrorObject }) => void

  private errorCaptured(err: any) {
    this.addErrorToStore({
      item: {
        message: `An unexpected error occurred: ${err}`,
      } as ErrorObject,
    })

    return true
  }

  @Watch('$route')
  private onRouteChange() {
    this.$gtag.event('view_item', {
      description: this.$route.fullPath,
    })
  }
}
