import { Vue } from 'vue-property-decorator'
import Router from 'vue-router'

import Home from '@/views/Home.vue'
import Search from '@/views/Search.vue'
import PartnerDetail from '@/views/PartnerDetail.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/search/:partnerID?',
      name: 'Search',
      props: true,
      component: Search,
    },
    {
      path: '/partner/:partnerID',
      name: 'Partner',
      props: true,
      component: PartnerDetail,
    },
  ],
})

export default router
