



















import uuid from 'uuid'
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/models/SelectOption'
import InputLabel from '@/elements/InputLabel.vue'

@Component({
  components: {
    InputLabel,
  },
})
export default class SelectInput extends Vue {
  private readonly guid = uuid.v4()

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ required: true, type: String })
  private readonly value!: string

  @Prop({ type: Array, required: true })
  private readonly options!: SelectOption[]

  private internalValue = this.value

  public get elementId() {
    return `select-${this.guid}`
  }

  private handleChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    if (this.internalValue !== this.value) {
      this.internalValue = this.value
    }
  }
}
