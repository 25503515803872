import restService from '@/services/restService'
import { LeagueInfo } from '@/models/LeagueInfo'

const baseUrl = 'partnerlookup/'

const retrieve = async (partnerID: string) => {
  return await restService.get<LeagueInfo[]>(`${baseUrl}${partnerID}`)
}

export default {
  retrieve,
}
