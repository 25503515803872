import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './rootState'
import VuexPersist from 'vuex-persist'

import { errors } from './errors'
import { search } from './search'
import { partnerProgram } from './partnerProgram'

Vue.use(Vuex)

const vuexPersist = new VuexPersist<RootState>({
  storage: window.sessionStorage,
})

const store: StoreOptions<RootState> = {
  modules: {
    errors,
    search,
    partnerProgram,
  },
  plugins: [vuexPersist.plugin],
}

export default new Vuex.Store<RootState>(store)
