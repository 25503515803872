















import uuid from 'uuid'
import InputLabel from '@/elements/InputLabel.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  components: {
    InputLabel,
  },
})
export default class TextInput extends Vue {
  private readonly guid = uuid.v4()

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: String, required: true })
  private readonly value!: string

  private internalValue: string = this.value

  public get elementId() {
    return `text-${this.guid}`
  }

  private handleInput() {
    this.$emit('input', this.internalValue)
  }

  private handleChange() {
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    this.internalValue = this.value
  }
}
