import restService from '@/services/restService'
import { PartnerProgramInfo } from '@/models/PartnerProgramInfo'

const baseUrl = 'programlookup/v2/'

const retrieve = async (sport: string, programType: string, postalCode: string, radius: number) => {
  return await restService.get<PartnerProgramInfo[]>(
    `${baseUrl}${sport}/${programType}/${postalCode}/${radius}`
  )
}

export default {
  retrieve,
}
