import { Module, GetterTree, MutationTree } from 'vuex'
import { RootState } from './rootState'
import { ErrorObject } from '@/models/ErrorObject'

interface ErrorsState {
  items: ErrorObject[]
}

const errorsState: ErrorsState = {
  items: [],
}

export enum getterNames {
  allItems = 'allItems',
  currentItem = 'currentItem',
}

const getters: GetterTree<ErrorsState, RootState> = {
  [getterNames.allItems]: state => state.items,
  [getterNames.currentItem]: state => state.items[0] || null,
}

export enum mutationNames {
  add = 'add',
  clearCurrent = 'clearCurrent',
  clearAll = 'clearAll',
}

const mutations: MutationTree<ErrorsState> = {
  [mutationNames.add](state, { item }: { item: ErrorObject }) {
    state.items.push(item)
  },
  [mutationNames.clearCurrent](state) {
    if (state.items.length) {
      state.items.shift()
    }
  },
  [mutationNames.clearAll](state) {
    state.items = []
  },
}

export const namespace = 'errors'

export const errors: Module<ErrorsState, RootState> = {
  namespaced: true,
  state: errorsState,
  getters,
  mutations,
}
